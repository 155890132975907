import * as Sentry from "@sentry/browser";
import App from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { FC } from "react";
import { GrLogout, GrMail, GrPhone } from "react-icons/gr";
import { NavItem } from "../components/Navigation";
import { Sidebars } from "../components/Sidebars";
import { withApollo } from "../components/WithApollo";
import EmailTemplateContextProvider from "../contexts/EmailTemplateContext";
import { LayoutContextProvider } from "../contexts/LayoutContext";
import RepairContextProvider, { useRepairContext } from "../contexts/RepairContext";
import UserContextProvider, { useUserContext } from "../contexts/UserContext";
import { parseJwt } from "../helpers/functions";
import cn from "classnames";
import "../styles/index.css";
import { needsReturnRepairCondition } from "./repair/needs-return";
import { PostHogProvider } from "posthog-js/react";

Sentry.init({
  dsn: "https://32e1112a54b344ee866807fe66280aaf@o410960.ingest.sentry.io/5286324",
  enabled: process.env.NODE_ENV === "production",
});

export function CountLabel(props) {
  const { label, className } = props;
  return (
    <div
      className={cn(
        "flex items-center justify-center w-6 h-6 mr-1 pt-0.5 font-mono text-xs font-semibold text-white bg-black rounded-full",
        className
      )}
    >
      {label}
    </div>
  );
}

const SiteHeader: FC<{ theme: "colosseum" | "winkelstueck-reparatur" | "desos" }> = ({ theme }) => {
  const { userData, isAdmin, isColosseumManager, isColosseumUser } = useUserContext();
  const isColosseum = isColosseumManager || isColosseumUser;
  return (
    <div className="fixed z-50 flex items-center justify-between w-full h-16 bg-primary">
      <div className="flex items-center self-stretch">
        <a
          href={theme === "colosseum" ? "https://www.colosseumdental.de/" : "https://winkelstueck-reparatur.de"}
          target="_blank"
          className="flex items-center w-56 h-full mr-4 bg-white"
        >
          {theme === "colosseum" && (
            <img src="/Colosseum Logo.svg" alt="Winkelstück Reparatur Logo" className="block w-64 px-4 bg-white" />
          )}
          {theme === "winkelstueck-reparatur" && (
            <img src="/Winkelstück Reparatur.jpg" alt="Winkelstück Reparatur Logo" className="block bg-primary w-60" />
          )}
          {theme === "desos" && (
            <img
              src="/desos/Logo_weiß.svg"
              alt="Winkelstück Reparatur Logo"
              className="block py-2 bg-primary w-60"
              style={{ height: "-webkit-fill-available" }}
            />
          )}
        </a>
        <p className="text-3xl text-white">
          Reparaturportal
          {isColosseum && (
            <span className="relative text-xs">
              {" "}
              powered by <span className="font-semibold">Desos</span>
            </span>
          )}
        </p>
      </div>
      {userData && (
        <div className="flex items-center mr-4">
          <p className="mr-4 text-white">
            <span className="font-medium">
              Hallo {userData.firstName} {userData.lastName} {isAdmin ? "(Admin)" : ""}
            </span>
          </p>
          <GrLogout
            className="cursor-pointer"
            onClick={() => {
              localStorage.removeItem("jwtToken");
              location.reload();
            }}
          />
        </div>
      )}
    </div>
  );
};

const Layout: FC<{ theme: "colosseum" | "winkelstueck-reparatur" | "desos" }> = ({ theme, children }) => {
  const router = useRouter();
  const { token: userToken, isAdmin, isColosseumManager, isColosseumUser, isDesos, called } = useUserContext();
  const isColosseum = isColosseumManager || isColosseumUser;
  const allRepairsCount = useRepairContext().repairs.length;
  const createdRepairsCount = useRepairContext().repairs.filter((repair) => ["created"].includes(repair.status)).length;
  const costEstimateRepairCount = useRepairContext().repairs.filter((repair) =>
    ["cost_estimate_available"].includes(repair.status)
  ).length;
  const finishedRepairsCount = useRepairContext().archivedRepairs.length;
  const needsReturnCount = useRepairContext().archivedRepairs.filter(needsReturnRepairCondition).length;

  if (typeof window !== "undefined") {
    if (
      !userToken &&
      router.pathname !== "/login" &&
      router.pathname !== "/register" &&
      router.pathname !== "/external-order"
    ) {
      router.push("/login");
      return null;
    }
    if (router.pathname === "/") {
      isAdmin ? router.push("/repair/created") : router.push("/repair/processing");
      return null;
    }
  }
  if (router.pathname === "/login" || router.pathname === "/register" || router.pathname === "/external-order") {
    return (
      <>
        <div className="h-screen">
          <div className="flex flex-col min-h-full px-12 py-10">{children}</div>
        </div>
      </>
    );
  }
  if (userToken && called && typeof window !== "undefined") {
    const currentDomain = getThemeFromDomain(location.host);
    const hash = `token=${localStorage.getItem("jwtToken")}`;
    if (currentDomain !== "colosseum" && isColosseum) {
      location.hash = hash;
      location.host = process.env.NEXT_PUBLIC_COLOSSEUM_DOMAIN;
    }
    if (currentDomain !== "desos" && isDesos && !isColosseum) {
      location.hash = hash;
      location.host = process.env.NEXT_PUBLIC_DESOS_DOMAIN;
    }
    if (currentDomain !== "winkelstueck-reparatur" && !isDesos && !isColosseum) {
      location.hash = hash;
      location.host = process.env.NEXT_PUBLIC_WR_DOMAIN;
    }
  }
  // return (
  //   <LayoutContextProvider router={router}>
  //     <TailwindLayout>{children}</TailwindLayout>
  //     <Sidebars />
  //   </LayoutContextProvider>
  // );
  return (
    <LayoutContextProvider router={router}>
      <SiteHeader theme={theme} />
      <div className="h-screen pt-16">
        <div className="relative">
          <div className="fixed top-0 bottom-0 flex flex-col w-56 mt-16 bg-gray-300 Navigation">
            {/* <NavItem href="/">Home</NavItem> */}
            <NavItem>
              Reparaturen
              <NavItem href="/repair/order">Beantragen</NavItem>
              {isAdmin && (
                <NavItem href="/repair/created">
                  <CountLabel label={createdRepairsCount} color="black" className="-ml-7" />
                  <span>Neu erstellte</span>
                </NavItem>
              )}
              <NavItem href="/repair/processing">
                <CountLabel
                  label={isAdmin ? allRepairsCount - createdRepairsCount : costEstimateRepairCount}
                  color="black"
                  className="-ml-7"
                />
                <span>In Bearbeitung</span>
              </NavItem>
              {isAdmin && (
                <NavItem href="/repair/needs-return">
                  {needsReturnCount > 0 && (
                    <span className="flex w-3 h-3 -ml-6 mr-3">
                      <span className="absolute inline-flex w-3 h-3 bg-pink-400 rounded-full opacity-75 animate-ping"></span>
                      <span className="relative inline-flex w-3 h-3 bg-pink-500 rounded-full"></span>
                    </span>
                  )}
                  <span>Ohne Rücksendelabel</span>
                </NavItem>
              )}
              <NavItem href="/repair/finished">
                {/* <StatusLabel label={finishedRepairsCount} color="black" /> */}
                <span>Abgeschlossen</span>
              </NavItem>
              <NavItem href="/handpieces">
                <span>Handstücke</span>
              </NavItem>
            </NavItem>
            {isAdmin && (
              <NavItem border>
                Kunden
                <NavItem href="/users">Übersicht</NavItem>
                <NavItem href="/users/new">Neue Kunden</NavItem>
              </NavItem>
            )}
            <NavItem border>
              {isAdmin ? "Administration" : "Mein Konto"}
              <NavItem href="/settings">Einstellungen</NavItem>
            </NavItem>
            <div className="flex-1"></div>
            <NavItem border>
              Support
              {isColosseum || isDesos ? (
                <NavItem href="mailto:contact@desos.eu" external={true}>
                  <GrMail className="inline-block" /> &nbsp;E-Mail senden
                </NavItem>
              ) : (
                <NavItem href="mailto:info@winkelstueck-reparatur.de" external={true}>
                  <GrMail className="inline-block" /> &nbsp;E-Mail senden
                </NavItem>
              )}
              {isColosseum || isDesos ? (
                <NavItem href="tel:+4975118086452" external={true}>
                  <GrPhone className="inline-block" /> +49 751 18 086 452
                </NavItem>
              ) : (
                <NavItem href="tel:+4983319853360" external={true}>
                  <GrPhone className="inline-block" /> +49 8331 98 53 360
                </NavItem>
              )}
              <NavItem href="tel:+4233926566" external={true}>
                <GrPhone className="inline-block" /> +423 392 65 66
              </NavItem>
            </NavItem>
            {/* <NavItem border href="/logout">
            Ausloggen
          </NavItem> */}
          </div>
          <div className="relative min-h-full px-12 py-10 ml-56 overflow-x-auto">{children}</div>
          <Sidebars />
        </div>
      </div>
    </LayoutContextProvider>
  );
};

export const getThemeFromDomain = (domain: string) => {
  switch (domain) {
    case "colosseum.desos.local.localhost":
    case "colosseum.desos.eu":
      return "colosseum";
    case "desos.local.localhost":
    case "portal.desos.eu":
      return "desos";
    default:
      return "winkelstueck-reparatur"; //throw new Error("Domain not found")
  }
};

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps: { ...pageProps, domain: ctx.req?.headers?.host || location.host } };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      const token = typeof window === "undefined" ? undefined : localStorage.getItem("jwtToken");
      if (token) {
        const parsed = parseJwt(token);
        scope.setExtra("user", parsed);
      }
      scope.setExtra("localStorage", localStorage);
      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;
    const isColosseum = getThemeFromDomain(pageProps.domain) === "colosseum";
    const isDesos = getThemeFromDomain(pageProps.domain) === "desos";
    const isWR = getThemeFromDomain(pageProps.domain) === "winkelstueck-reparatur";

    return (
      <div className={getThemeFromDomain(pageProps.domain)}>
        <Head>
          {isColosseum && (
            <>
              <title>Reparaturportal - colosseum.desos.eu</title>
              <meta property="og:title" content="Reparaturportal - colosseum.desos.eu" />
              <meta property="og:site_name" content="colosseum.desos.eu" />
              <meta property="twitter:title" content="Reparaturportal - colosseum.desos.eu" />
              <link rel="icon" type="image/svg+xml" href="/colosseum/favicon.svg" />
              <link rel="icon" type="image/png" href="/colosseum/favicon.png" />
            </>
          )}
          {isWR && (
            <>
              <title>Reparaturportal - winkelstueck-reparatur.de</title>
              <meta property="og:title" content="Reparaturportal - winkelstueck-reparatur.de" />
              <meta property="og:site_name" content="winkelstueck-reparatur.de" />
              <meta property="twitter:title" content="Reparaturportal - winkelstueck-reparatur.de" />
              <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
              <link rel="manifest" href="/site.webmanifest" />
            </>
          )}
          {isDesos && (
            <>
              <title>Reparaturportal - desos.eu</title>
              <meta property="og:title" content="Reparaturportal - desos.eu" />
              <meta property="og:site_name" content="desos.eu" />
              <meta property="twitter:title" content="Reparaturportal - desos.eu" />
              <link rel="apple-touch-icon" sizes="180x180" href="/desos/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/desos/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/desos/favicon-16x16.png" />
              <link rel="manifest" href="/desos/site.webmanifest" />
              <link rel="mask-icon" href="/desos/safari-pinned-tab.svg" color="#5bbad5" />
              <link rel="shortcut icon" href="/desos/favicon.ico" />
              <meta name="msapplication-TileColor" content="#00aba9" />
              <meta name="msapplication-config" content="/desos/browserconfig.xml" />
              <meta name="theme-color" content="#ffffff"></meta>
            </>
          )}
          {/* <script src="https://cdnjs.cloudflare.com/ajax/libs/core-js/2.6.11/core.min.js"></script> */}
        </Head>
        <UserContextProvider>
          <RepairContextProvider>
            <EmailTemplateContextProvider>
              <Layout theme={getThemeFromDomain(pageProps.domain)}>
                <Component {...pageProps} theme={getThemeFromDomain(pageProps.domain)} />
              </Layout>
            </EmailTemplateContextProvider>
          </RepairContextProvider>
        </UserContextProvider>
      </div>
    );
  }
}
export default withApollo()(MyApp);
